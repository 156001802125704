import React from 'react';
import { BusinessInfoGraphQl as useBusinessInfoQuery } from './business-info.graphql';
import { SEO } from '@matix/utils';

export const Seo = (props: any) => {
   if (!props.data.seoData || !props.data.seoData.breadcrumbs) {
      return <></>;
   }
   const queryBusiness = useBusinessInfoQuery()?.node.frontmatter;

   //Construct breadcrumbs from markdown links
   const seoBreadcrumbs = props.data.seoData.breadcrumbs.map((item: string, idx: number) => {
      const url = item.match(/\(([^)]+)\)/);
      const text = item.match(/\[(.*?)\]/);
      if (text && text.length && url && url.length) {
         return {
            '@type': 'ListItem',
            position: idx + 1,
            name: text[1],
            item: `${queryBusiness.siteUrl}${url[1]}`,
         };
      } else {
         return {
            '@type': 'ListItem',
            position: idx + 1,
            name: item,
         };
      }
   });

   return <SEO siteMeta={queryBusiness} pagesMeta={{ ...props.data.seoData }} breadcrumbs={seoBreadcrumbs} />;
};
