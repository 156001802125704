import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Seo } from '../components/seo/seo';
import { LocalImagesContext } from '@matix/utils';

export default (props: any) => {
   const provider = props.data.seoMetaData.edges.find((x: any) => x.node.frontmatter.category === props.pageContext.provider).node.frontmatter
      .breadcrumbs;
   const category = props.data.seoMetaData.edges.find((x: any) => x.node.frontmatter.category === props.pageContext.category).node.frontmatter
      .breadcrumbs;
   const localImagesDesktop = props.data.localImagesDesktop.edges.map((edge: any) => {
      return { ...edge.node, IsMobile: false };
   });
   const localImagesMobile = props.data.localImagesMobile.edges.map((edge: any) => {
      return { ...edge.node, IsMobile: true };
   });

   props.data.seoData = {
      title: props.data.productData.frontmatter.name,
      description: props.data.productData.frontmatter.description,
      keywords: props.data.productData.frontmatter.keywords,
      locale: props.data.productData.frontmatter.locale,
      breadcrumbs: [...provider, category[category.length - 1], props.data.productData.frontmatter.name],
   };

   return (
      <LocalImagesContext.Provider
         value={{
            images: [...props.data.productData.productImages, ...localImagesDesktop, ...localImagesMobile],
            basePath: props.pageContext.localImageBasePath,
         }}
      >
         <Seo {...props} />
         <MDXRenderer {...props.data}>{props.data.template.body}</MDXRenderer>
      </LocalImagesContext.Provider>
   );
};

export const pageQueryTwo = graphql`
   query productQuery($template: String, $locale: String, $slug: String, $category: String, $provider: String, $localImages: [String]) {
      template: mdx(frontmatter: { template: { eq: $template }, locale: { eq: $locale } }) {
         id
         body
      }

      seoMetaData: allMdx(
         filter: { frontmatter: { type: { eq: "category-seo" }, locale: { eq: $locale }, category: { in: [$category, $provider] } } }
      ) {
         edges {
            node {
               ...SeoFragment
            }
         }
      }

      productData: mdx(frontmatter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
         ...ProductFragment
      }

      localImagesDesktop: allFile(filter: { sourceInstanceName: { eq: "images" }, name: { in: $localImages } }) {
         edges {
            node {
               relativePath
               name
               childImageSharp {
                  fluid(maxWidth: 930, quality: 75) {
                     ...GatsbyImageSharpFluid_withWebp_noBase64
                     presentationWidth
                  }
               }
            }
         }
      }

      localImagesMobile: allFile(filter: { sourceInstanceName: { eq: "images" }, name: { in: $localImages } }) {
         edges {
            node {
               relativePath
               name
               childImageSharp {
                  fluid(maxWidth: 360, quality: 75) {
                     ...GatsbyImageSharpFluid_withWebp_noBase64
                     presentationWidth
                  }
               }
            }
         }
      }

      grouping: allMdx(filter: { frontmatter: { type: { eq: "product" }, locale: { eq: $locale } } }) {
         categories: group(field: frontmatter___category) {
            fieldValue
            totalCount
         }
         providers: group(field: frontmatter___provider) {
            fieldValue
            totalCount
         }
      }
   }
`;
